"use client";
import { clsx } from "clsx";
import { ImageProps as NextImageProps, getImageProps } from "next/image";
import {
  ReactEventHandler,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AppContext, Box, BoxProps, PartialBy, constants, envUiConfig } from "@hkexpressairwayslimited/ui/src";
import classes from "./Image.module.scss";

export type ImageProps = {
  height?: string | NextImageProps["height"];
  width?: string | NextImageProps["width"];
} & Omit<PartialBy<NextImageProps, "src" | "alt">, "width" | "height"> &
  Omit<BoxProps, keyof NextImageProps>;

export type ImageContextType = {
  forceUnoptimized?: boolean;
};

export const ImageContext = createContext<ImageContextType>({
  forceUnoptimized: false,
});

export const IsPx = (value?: ImageProps["width"]): value is number | `${number}` | `${number}px` => {
  if (!value || value.toString().length > 1000) {
    return false;
  } else {
    return /[0-9]+(px){0,1}$/.test(value.toString());
  }
};

// Handle the image component
// - If the image is not loaded, show a placeholder image
// - During image page load, show a blur image
export const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ src = "", alt = "", className, onLoad, onError, style = {}, ...props }, ref) => {
    const { forceUnoptimized } = useContext(ImageContext);
    const { blurImageList } = useContext(AppContext);
    const [loaded, setLoaded] = useState(false);
    const source = useMemo(() => (typeof src === "string" ? new URL(src, envUiConfig.publicUrl).href : src), [src]);
    const altText = useMemo(
      () => alt || (typeof src === "string" ? new URL(src, "https://-").pathname.split("/").pop() || "" : ""),
      [alt, src]
    );
    const foundBlurImage = useMemo(
      () => (typeof src === "string" ? blurImageList?.[src] : undefined),
      [src, blurImageList]
    );
    const [isError, setIsError] = useState(false);
    const unoptimized = useMemo(() => forceUnoptimized || props.unoptimized, [forceUnoptimized, props.unoptimized]);
    const isSvg = useMemo(() => typeof src === "string" && new URL(src, "https://-").pathname.endsWith(".svg"), [src]);
    const needOptimize = useMemo(() => !unoptimized && typeof src === "string" && !isSvg, [isSvg, unoptimized, src]);
    const isBlur = useMemo(
      () => typeof src !== "string" || (needOptimize && foundBlurImage),
      [needOptimize, foundBlurImage]
    );
    const defaultHeight = useMemo(
      () => (!props.fill ? (IsPx(props.height) ? parseFloat(props.height.toString()) : 0) : undefined),
      [props.height, props.fill]
    );
    const defaultWidth = useMemo(
      () =>
        !props.fill
          ? IsPx(props.width)
            ? parseFloat(props.width.toString())
            : parseFloat(constants.screenDesktopMinValue)
          : undefined,
      [props.width, props.fill]
    );
    const defaultStyle = useMemo(
      () => ({
        ...(!IsPx(props.height) ? { height: props.height } : {}),
        ...(!IsPx(props.width) ? { width: props.width } : {}),
        ...style,
      }),
      [props.height, props.width, style]
    );
    const blurDataURL = useMemo(
      () => (typeof src === "string" && isBlur ? (foundBlurImage ? foundBlurImage : undefined) : undefined),
      [src, props.width, isBlur]
    );
    const {
      props: { height, width, ...others },
    } = getImageProps({
      placeholder: isBlur && !loaded ? "blur" : undefined,
      blurDataURL,
      alt: altText,
      style: defaultStyle,
      sizes: "100vw",
      ...props,
      unoptimized,
      src: source,
      height: defaultHeight,
      width: defaultWidth,
    });

    const handleLoadSuccess: ReactEventHandler<HTMLImageElement> = useCallback((event) => {
      if (!isError) {
        setLoaded(true);
        onLoad?.(event);
      }
    }, []);
    const handleLoadError: ReactEventHandler<HTMLImageElement> = useCallback((event) => {
      if (!isError) {
        setIsError(true);
        onError?.(event);
      }
    }, []);

    useEffect(() => {
      setLoaded(false);
      setIsError(false);
    }, [src]);

    return (
      <Box
        component='img'
        className={clsx(classes.image, className)}
        height={props.height ? height : undefined}
        width={props.width ? width : undefined}
        ref={ref}
        {...others}
        onLoad={!isError ? handleLoadSuccess : undefined}
        onError={handleLoadError}
        data-source={typeof src === "string" ? src : undefined}
      />
    );
  }
);
Image.displayName = "Image";

import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps, useContext, useMemo } from 'react';
import { renderToString } from 'react-dom/server';

import { AppContext, ImageContext, parseHtmlToReact } from '@hkexpressairwayslimited/ui';
import classes from './JssRichText.module.scss';

export const JssRichText = ({ field, ...others }: ComponentProps<typeof RichText>) => {
  const { isInEditor } = useContext(AppContext);
  const reactHtml = useMemo(() => {
    try {
      return parseHtmlToReact(field?.value || '');
    } catch (err) {
      return null;
    }
  }, [field?.value]);
  const sanitizedField = useMemo(
    () =>
      field
        ? {
            ...field,
            value: renderToString(
              <ImageContext.Provider value={{ forceUnoptimized: true }}>
                {reactHtml}
              </ImageContext.Provider>
            ),
          }
        : field,
    [reactHtml]
  );

  return isInEditor ? (
    <RichText className={classes.jssRichText} field={sanitizedField} {...others} />
  ) : (
    reactHtml
  );
};

import Script from "next/script";

declare global {
  interface Window {
    qualtricsCulture?: string;
  }
}

export enum QualtricsLanguageCode {
  SQI = "SQI",
  NL = "NL",
  KAN = "KAN",
  RO = "RO",
  AR = "AR",
  EN_GB = "EN-GB",
  KAZ = "KAZ",
  RU = "RU",
  HYE = "HYE",
  EN = "EN",
  KM = "KM",
  SR = "SR",
  ASM = "ASM",
  EO = "EO",
  KO = "KO",
  SIN = "SIN",
  AZ_AZ = "AZ-AZ",
  ET = "ET",
  LV = "LV",
  SK = "SK",
  ID = "ID",
  FI = "FI",
  LT = "LT",
  SL = "SL",
  MS = "MS",
  FR = "FR",
  MK = "MK",
  ES_ES = "ES-ES",
  EU = "EU",
  BEL = "BEL",
  FR_CA = "FR-CA",
  MAL = "MAL",
  ES = "ES",
  BN = "BN",
  KAT = "KAT",
  MAR = "MAR",
  SW = "SW",
  BS = "BS",
  DE = "DE",
  MN = "MN",
  SV = "SV",
  PT_BR = "PT-BR",
  EL = "EL",
  SR_ME = "SR-ME",
  TGL = "TGL",
  BG = "BG",
  GU = "GU",
  MY = "MY",
  TA = "TA",
  CA = "CA",
  HE = "HE",
  NE = "NE",
  TEL = "TEL",
  CEB = "CEB",
  HI = "HI",
  NO = "NO",
  TH = "TH",
  ZH_S = "ZH-S",
  HU = "HU",
  ORI = "ORI",
  TR = "TR",
  ZH_T = "ZH-T",
  HIL = "HIL",
  FA = "FA",
  UK = "UK",
  HR = "HR",
  IT = "IT",
  PL = "PL",
  UR = "UR",
  CS = "CS",
  ISL = "ISL",
  PT = "PT",
  VI = "VI",
  DA = "DA",
  JA = "JA",
  PA_IN = "PA-IN",
  CY = "CY",
}

export const qualtricsLocaleMap: Record<string, QualtricsLanguageCode> = {
  "zh-CN": QualtricsLanguageCode.ZH_S,
};

export const qualtricsLangMap: Record<string, QualtricsLanguageCode> = {
  ZH: QualtricsLanguageCode.ZH_T,
  ...QualtricsLanguageCode,
};

export const mapQualtricsLanguageCode = (lang: string): QualtricsLanguageCode | null => {
  const qualtricsLang = qualtricsLocaleMap[lang];
  const langKey = lang.split("-")[0]?.toUpperCase() || "";

  return (
    qualtricsLang || (langKey in qualtricsLangMap ? qualtricsLangMap[langKey as keyof typeof qualtricsLangMap] : null)
  );
};

export const setQualtricsLang = (lang: string) => {
  const qualtricsLang = mapQualtricsLanguageCode(lang);

  if (qualtricsLang) {
    window.qualtricsCulture = qualtricsLang;
  }
};

export const Qualtrics = ({ url }: { url?: string }) => {
  if (!url) return;

  const id = new URL(url).searchParams.get("Q_ZID");

  if (!id) {
    return;
  }

  return (
    <>
      <Script id='qualtrics' defer strategy='lazyOnload'>
        {`
(function(){var g=function(e,h,f,g){

this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};

this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};

this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};

this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};

this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};

try{(new g(100,"r","QSI_S_${id}","${url}")).start()}catch(i){}})();
`}
      </Script>
      <div id={id}></div>
    </>
  );
};

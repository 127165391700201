import { LoadingPlaneIcon } from "@hkexpressairwayslimited/ui/src";
import clsx from "clsx";

import classes from "./LoadingBackdrop.module.scss";

export interface LoadingBackdropProps {
  isOpen: boolean;
  zIndex?: number | string;
  hideBackground?: boolean;
}

const LoadingBackdrop = ({ isOpen, zIndex, hideBackground = false }: LoadingBackdropProps) => {
  if (!isOpen) return;

  return (
    <div
      className={clsx(classes.pageLoader, { [classes.pageLoader__hideBackground]: hideBackground })}
      style={{ zIndex: zIndex ?? 1024 }}
    >
      <div className={classes.pageLoader_loaderWrap}>
        <div className={classes.pageLoader_loading}>
          <div className={classes.pageLoader_loadingPlaneContainer}>
            <span className={clsx(classes.pageLoader_loadingPlane, classes.pageLoader_plane1)}>
              <LoadingPlaneIcon />
            </span>
            <span className={clsx(classes.pageLoader_loadingPlane, classes.pageLoader_plane2)}>
              <LoadingPlaneIcon />
            </span>
            <span className={clsx(classes.pageLoader_loadingPlane, classes.pageLoader_plane3)}>
              <LoadingPlaneIcon />
            </span>
            <span className={clsx(classes.pageLoader_loadingPlane, classes.pageLoader_plane4)}>
              <LoadingPlaneIcon />
            </span>
            <span className={clsx(classes.pageLoader_loadingPlane, classes.pageLoader_plane5)}>
              <LoadingPlaneIcon />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LoadingBackdrop };

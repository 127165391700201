import {
  AppContext,
  Button,
  ButtonVariant,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  DelayIcon,
  Header,
  Section,
  SvgIcon,
  SvgIconSize,
  Text,
  TextSize,
  WarningAlertIcon,
  colors,
  usePlaceholderChildGroups,
} from "@hkexpressairwayslimited/ui/src";
import clsx from "clsx";
import { Children, ReactNode, createContext, useCallback, useContext, useEffect, useRef, useState } from "react";

import classes from "./AlertMessageBar.module.scss";

export type AlertMessageBarProps = {
  messages?: ReactNode;
  interval?: number;
  onClose?: () => void;
};

export type AlertMessageProps = {
  className?: string;
  title?: ReactNode;
  content?: ReactNode;
  iconType?: IconTypes;
};

enum IconTypes {
  warning = "warning",
  delay = "delay",
}

const AlertMessage = ({ title, content, iconType = IconTypes.warning, ...others }: AlertMessageProps) => {
  const { isDesktop } = useContext(Header.HeaderContext);
  const { currentIndex } = useContext(AlertMessageBarContext);
  const { index } = useContext(AlertMessageContext);
  const { controls } = useContext(AlertMessageBarContext);

  const msgContent = <Text className={classes.alertMessageBar_messageContent}>{content}</Text>;
  return (
    <div
      className={clsx(classes.alertMessageBar_messageWrapper, {
        [classes.alertMessageBar_messageWrapper__hidden]: currentIndex !== index,
      })}
      {...others}
    >
      <div className={classes.alertMessageBar_message}>
        <SvgIcon size={SvgIconSize.Desktop}>
          {iconType === IconTypes.warning ? <WarningAlertIcon /> : null}
          {iconType === IconTypes.delay ? <DelayIcon /> : null}
        </SvgIcon>
        <Text className={classes.alertMessageBar_messageTitle} bold>
          {title}
        </Text>
        {isDesktop ? msgContent : controls}
      </div>
      {!isDesktop ? msgContent : controls}
    </div>
  );
};
AlertMessage.IconTypes = IconTypes;

const AlertMessageBarContext = createContext<{ currentIndex?: number; controls?: ReactNode }>({});
const AlertMessageContext = createContext<{ index?: number }>({});

export const AlertMessageBar = ({ messages = [], interval = 4, onClose }: AlertMessageBarProps) => {
  const { isInEditor } = useContext(AppContext);
  const [mouseEnteredBar, setMouseEnteredBar] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutTimerRef = useRef<NodeJS.Timeout | null>();
  const { openPlaceholder, closePlaceholder, children } = usePlaceholderChildGroups(messages);
  const messagesArray = Children.toArray(children);
  const maxLength = messagesArray.length;

  const prevMessage = useCallback(() => {
    if (currentIndex === 0) {
      setCurrentIndex(maxLength - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  }, [currentIndex, messages, setCurrentIndex]);

  const nextMessage = useCallback(() => {
    if (currentIndex === maxLength - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
    clearTimer();
  }, [currentIndex, messages, setCurrentIndex]);

  // Clear timer function
  const clearTimer = useCallback(() => {
    if (timeoutTimerRef.current) {
      clearInterval(timeoutTimerRef.current);
      timeoutTimerRef.current = null;
    }
  }, []);
  // Timer function
  const timer = useCallback(() => {
    clearTimer();
    timeoutTimerRef.current = setTimeout(() => {
      nextMessage();
      timer();
    }, interval * 1000);
  }, [clearTimer, nextMessage, interval]);

  // Clear timer when mouse enters the bar
  useEffect(() => {
    if (!isInEditor) {
      if (mouseEnteredBar) {
        clearTimer();
      } else {
        timer();
      }
    }
  }, [mouseEnteredBar, isInEditor]);

  // Start timer when component is mounted
  useEffect(() => {
    if (!isInEditor) {
      timer();
    }

    return clearTimer;
  }, [timer, clearTimer, isInEditor]);

  const controls = (
    <div className={classes.alertMessageBar_controls}>
      {maxLength > 1 && (
        <div className={classes.alertMessageBar_messageControl}>
          <Button custom onClick={prevMessage}>
            <SvgIcon>
              <ChevronLeftIcon />
            </SvgIcon>
          </Button>
          <Text size={TextSize.P3Regular}>{`${currentIndex + 1}/${maxLength}`}</Text>
          <Button custom onClick={nextMessage}>
            <SvgIcon>
              <ChevronRightIcon />
            </SvgIcon>
          </Button>
        </div>
      )}

      <Button
        onClick={onClose ?? onClose}
        variant={ButtonVariant.Icon}
        iconSize={SvgIconSize.Desktop}
        color={colors.neutralBlack}
        noBorder
      >
        <CloseIcon />
      </Button>
    </div>
  );

  return (
    <AlertMessageBarContext.Provider value={{ currentIndex, controls }}>
      <div
        className={classes.alertMessageBar}
        onMouseEnter={() => setMouseEnteredBar(true)}
        onMouseLeave={() => setMouseEnteredBar(false)}
      >
        <Section className={classes.alertMessageBar_messageList}>
          {openPlaceholder}
          {messagesArray.map((message, index) => (
            <AlertMessageContext.Provider value={{ index }} key={index}>
              {message}
            </AlertMessageContext.Provider>
          ))}
          {closePlaceholder}
        </Section>
      </div>
    </AlertMessageBarContext.Provider>
  );
};
AlertMessageBar.AlertMessage = AlertMessage;
AlertMessageBar.AlertMessageBarContext = AlertMessageBarContext;
AlertMessageBar.AlertMessageContext = AlertMessageContext;

import { envConfig } from './env';

export const endpoints = {
  currencyRate: `${envConfig.endPointFlightBookingQueryHost}/public/v1/currency-rate`,
  endpointContent: envConfig.endpointContent + '/non-pss-int/public/v1/content-management/list',

  flightRouteMapping: `${envConfig.endpointAdminHost}/public/v1/flight-route-mapping`,
  subscriptionByEmail: `${envConfig.endpointNotificationHost}/v1/mail/add-recipient-into-list`,
  // flightRouteMapping: 'https://run.mocky.io/v3/005cc2e5-ccaf-4a54-a079-2f80fa290593', // this is mock data
};

export const fetchAPI = async (url: string, options: RequestInit = {}) => {
  const response = await fetch(url, {
    ...options,
    headers: { 'Content-Type': 'application/json', ...options.headers },
    mode: 'cors',
    redirect: 'follow',
  });

  const result = await response.json();
  if (result.error_code) {
    const message = `An error has occurred: ${result.error_message}`;
    console.log('message', message);
  }

  return result;
};
export const getAccessTokenHeader = (): {
  Authorization?: string;
} => {
  try {
    const t = localStorage.getItem('t');
    const { access_token: accessToken } = JSON.parse(t || '');
    if (accessToken) {
      return { Authorization: `Bearer ${accessToken}` };
    }
    return {};
  } catch (err) {
    return {};
  }
};
export const getApi = async (
  url: string,
  headers: HeadersInit = {},
  data?: { [key: string]: any }
) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers,
  };

  const response = await fetchAPI(url + (data ? `?${new URLSearchParams(data).toString()}` : ''), {
    ...requestOptions,
    headers: { ...requestOptions.headers, ...getAccessTokenHeader() },
  });

  return response;
};
export const postApi = async (url: string, body: object = {}, headers: HeadersInit = {}) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  };
  const response = await fetchAPI(url, {
    ...requestOptions,
    headers: { ...requestOptions.headers, ...getAccessTokenHeader() },
  });
  return response;
};
export const putApi = async (url: string, body: object = {}, headers: HeadersInit = {}) => {
  const requestOptions: RequestInit = {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  };
  const response = await fetchAPI(url, {
    ...requestOptions,
    headers: { ...requestOptions.headers, ...getAccessTokenHeader() },
  });
  return response;
};

export const parseJwt = (token: string) => {
  if (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } else {
    return {};
  }
};

import React, { Children, ReactElement, ReactNode, cloneElement, isValidElement, useContext, useMemo } from "react";
import { AppContext } from "../contexts";

// Meta Editing Mode
export const useHiddenGroup = (components: ReactNode, start: number, count: number) => {
  const { isInEditor, editMode } = useContext(AppContext);
  const { openPlaceholder, closePlaceholder, children } = usePlaceholderChildGroups(components);
  const childGroup = useMemo(() => {
    const componentsArray = Children.toArray(children);

    if (!isInEditor) {
      return { components: componentsArray.slice(start, start + count), count: componentsArray.length };
    }

    if (editMode === "metadata") {
      const metaPlaceholder = Children.toArray(children)[0];
      const metaComponents = isValidElement(metaPlaceholder)
        ? React.cloneElement(metaPlaceholder as ReactElement, {
            ...metaPlaceholder.props,
            children: Children.toArray(metaPlaceholder.props.children).map((metaComponent, index) => {
              if (start <= index && index < start + count) {
                return metaComponent;
              } else if (isValidElement(metaComponent)) {
                return React.cloneElement(metaComponent as ReactElement, {
                  ...metaComponent.props,
                  children: metaComponent.props.children
                    ? cloneElement(metaComponent.props.children as ReactElement, {
                        ...metaComponent.props.children.props,
                        children: cloneElement(metaComponent.props.children.props.children as ReactElement, {
                          ...metaComponent.props.children.props.children.props,
                          style: { display: "none" },
                        }),
                      })
                    : undefined,
                });
              }
              return metaComponent;
            }),
          })
        : undefined;

      return {
        components: metaComponents,
        count: Children.count(metaComponents?.props.children) || 0,
      };
    } else {
      return {
        components: [openPlaceholder, ...componentsArray.slice(start, start + count), closePlaceholder],
        count: componentsArray.length,
      };
    }
  }, [children, start, count, isInEditor]);

  return childGroup;
};

// Chromes Editing Mode
export const usePlaceholderChildGroups = (components: ReactNode) => {
  const { isInEditor } = useContext(AppContext);
  const childGroup = useMemo(() => {
    if (!isInEditor) {
      return { openPlaceholder: undefined, closePlaceholder: undefined, children: components };
    }

    const children: (ReactElement | undefined)[] = [];
    const validComponents: ReactElement<{
      type?: "text/sitecore";
      chrometype?: "placeholder" | "rendering";
      kind?: "open" | "close";
    }>[] = Children.toArray(components).filter((component): component is ReactElement => isValidElement(component));
    const actualComponents: ReactElement[] = validComponents.filter(
      (component) => component.props.type !== "text/sitecore"
    );

    const openPlaceholder = validComponents.find(
      (component) => component.props.kind === "open" && component.props.chrometype === "placeholder"
    );
    const openPlaceholderArr = [openPlaceholder];
    const closePlaceholder = validComponents.find(
      (component) => component.props.kind === "close" && component.props.chrometype === "placeholder"
    );
    const closePlaceholderArr = [closePlaceholder];

    actualComponents.forEach((component, index) => {
      const openRendering = validComponents.filter(
        (component) => component.props.kind === "open" && component.props.chrometype === "rendering"
      )[index];
      const closeRendering = validComponents.filter(
        (component) => component.props.kind === "close" && component.props.chrometype === "rendering"
      )[index];

      children.push(
        <>
          {openRendering}
          {component}
          {closeRendering}
        </>
      );
    });

    return { openPlaceholder: openPlaceholderArr, closePlaceholder: closePlaceholderArr, children };
  }, [components, isInEditor]);

  return childGroup;
};

type NestedObject = { [key: string]: string | NestedObject | NestedObject[] | ComponentObject } | NestedObject[];

export type ComponentObject = {
  uid: string;
  componentName: string;
  dataSource: string;
  params: {
    [key: string]: string;
  };
  fields: {
    [key: string]: {
      value: {
        [key: string]: string;
      };
    };
  };
};

export function mutateComponent(obj: NestedObject, callback: (componentObject: ComponentObject) => void): NestedObject {
  if (Array.isArray(obj)) {
    return obj.map((val) => mutateComponent(val, callback));
  } else if (typeof obj === "object" && obj !== null) {
    const result: { [key: string]: any } = {};
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        result[key] = mutateComponent(obj[key] as NestedObject, callback);
      } else {
        result[key] = obj[key]; // Include other types of values
      }
    }
    if ("componentName" in result) {
      console.log("asigning");
      callback(result as ComponentObject);
    }
    return result;
  }
  return obj;
}

import {
  ArrowDownIcon,
  Box,
  Button,
  ButtonVariant,
  EPixel,
  FieldInputGroup,
  Font,
  Form,
  Grid,
  Link,
  SvgIcon,
  Text,
  TextInput,
  useForm,
} from "@hkexpressairwayslimited/ui/src";
import { styled } from "@mui/material";
import { useEffect } from "react";
import * as yup from "yup";
import { BookingProps } from "../Booking";

const StyledButton = styled(Button)(({ theme }) => ({
  minHeight: "56px !important",
  minWidth: "initial !important",
}));

enum Fields {
  BookingRef = "BookingRef",
  LastName = "LastName",
  FirstName = "FirstName",
}

const defaultValues = {
  [Fields.BookingRef]: "",
  [Fields.LastName]: "",
  [Fields.FirstName]: "",
};

type FormValues = typeof defaultValues;

export function CheckIn({
  handleCheckInSubmit,
  i18nContent,
  handleManageMyBookingLink,
  hasLogin,
  curTab,
}: BookingProps) {
  const FormSchemas = {
    [Fields.BookingRef]: yup
      .string()
      .required(i18nContent.checkIn.bookingReferenceRequired)
      .matches(/^[a-zA-Z0-9]{6}$/, i18nContent.checkIn.referenceInvalid),
    [Fields.LastName]: yup
      .string()
      .required(i18nContent.checkIn.lastNameRequired)
      .matches(/^[a-zA-Z\s]*$/, i18nContent.checkIn.lastNameInvalid),
    [Fields.FirstName]: yup
      .string()
      .required(i18nContent.checkIn.firstNameRequired)
      .matches(/^[a-zA-Z\s]*$/, i18nContent.checkIn.firstNameInvalid),
  };
  const formHook = useForm<FormValues>({
    defaultValues,
    schema: yup.object().shape(FormSchemas),
    mode: "onSubmit",
  });

  useEffect(() => {
    if (curTab !== 2) {
      formHook.clearErrors();
    }
  }, [curTab]);

  const handleSubmit = (formValues: any) => {
    handleCheckInSubmit && handleCheckInSubmit(formValues);
  };
  const handleLink = () => {
    handleManageMyBookingLink && handleManageMyBookingLink();
  };
  return (
    <>
      <Box sx={{ mb: EPixel.px16 }}>
        <Text>
          {i18nContent.checkIn.text}
          {hasLogin && (
            <Link
              onClick={handleLink}
              sx={{ display: "inline-flex", alignItems: "center", ml: EPixel.px4 }}
              component='button'
              underline='none'
            >
              <Font fontWeight='fontWeightBold'>{i18nContent.manageBooking.retrieveMyTrip}</Font>
              <SvgIcon sx={{ ml: EPixel.px4, width: EPixel.px12, transform: "rotate(-90deg)" }}>
                <ArrowDownIcon />
              </SvgIcon>
            </Link>
          )}
        </Text>
      </Box>
      <Form formHook={formHook} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item md={10} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <FieldInputGroup
                  fullWidth
                  names={[Fields.BookingRef]}
                  helperText={i18nContent.checkIn.bookingReference}
                >
                  <TextInput fullWidth placeholder={i18nContent.checkIn.bookingReference} />
                </FieldInputGroup>
              </Grid>
              <Grid item md={4} xs={12}>
                <FieldInputGroup fullWidth names={[Fields.LastName]} helperText={i18nContent.checkIn.lastName}>
                  <TextInput fullWidth placeholder={i18nContent.checkIn.lastName} />
                </FieldInputGroup>
              </Grid>
              <Grid item md={4} xs={12}>
                <FieldInputGroup fullWidth names={[Fields.FirstName]} helperText={i18nContent.checkIn.firstName}>
                  <TextInput fullWidth placeholder={i18nContent.checkIn.firstName} />
                </FieldInputGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} xs={12} display='flex' alignItems='flex-start'>
            <StyledButton borderRadius={EPixel.px28} submit fullWidth variant={ButtonVariant.Primary}>
              {i18nContent.checkIn.checkIn}
            </StyledButton>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

import { Locale, enUS, ja, ko, zhCN, zhHK, zhTW } from "date-fns/locale";
export const localMap: { [K: string]: Locale } = {
  zh: zhCN,
  en: enUS,
  "en-HK": enUS,
  "zh-HK": zhHK,
  "zh-TW": zhTW,
  "zh-CN": zhCN,
  ko: ko,
  ja: ja,
};

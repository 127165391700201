import {
  Box,
  DotOutlinedSvg,
  EFont,
  ESpacing,
  FerryIcon,
  FlightIcon,
  Font,
  Journey,
  PlaceIcon,
  Segment,
  SvgIcon,
  SvgIconSize,
  Tag,
  lightTheme,
  useMobileQuery,
} from "@hkexpressairwayslimited/ui/src";
import { Stack, Theme, styled } from "@mui/material";

type FlightStatusVariant = "withDate" | "small" | "segment" | "withLocation";

type SegmentFlightStatus = {
  variant: "segment";
  flightSegment: Segment;
};

type JourneyFlightStatus = {
  variant: "withDate" | "small" | "withLocation";
  flightJourney: Journey;
};
type FlightStatusProps = {
  disabled?: boolean;
  classType?: string;
  flightType?: string;
  variant?: FlightStatusVariant;
  isDisplayCityName?: boolean;
  i18nContent?: { [key: string]: string | JSX.Element | JSX.Element[] };
  openDetailPopUp?: (event: any) => void;
} & (SegmentFlightStatus | JourneyFlightStatus);

type SegmentFlightIndicator = {
  variant: "segment";
  flight: Segment;
};

type JourneyFlightIndicator = {
  variant: "withDate" | "small" | "withLocation";
  flights: Segment[];
};

type FlightIndicatorProps = {
  disabled?: boolean;
  flightTime: string;
  isFerry?: boolean;
  variant?: FlightStatusVariant;
  i18nContent?: { [key: string]: string | JSX.Element | JSX.Element[] };
  openDetailPopUp?: (event: any) => void;
} & (SegmentFlightIndicator | JourneyFlightIndicator);

const SvgBox = styled(Box)(({ theme, disabled }: { theme: Theme } & { disabled: boolean }) => ({
  display: "flex",
  "& > svg > circle": {
    stroke: disabled ? theme.palette.neutral.disable : theme.palette.purple.default,
  },
}));

const DashedLine = ({ variant, disabled }: { variant: "single" | "multiple"; disabled?: boolean }) => {
  if (variant === "single") {
    return (
      <Box
        sx={{
          flexGrow: "1",
          height: "2px",
          width: "auto",
          marginBottom: "2px",
          marginInline: ESpacing._3xs,
          borderBottom: "2px dashed",
          color: disabled ? "neutral.disable" : "purple.default",
        }}
      />
    );
  } else if (variant === "multiple") {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "auto",
          width: "100%",
          marginBottom: "2px",
          marginInline: ESpacing._3xs,
          color: disabled ? "neutral.disable" : "purple.default",
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginInline: ESpacing._3xs,
            marginBottom: "3px",
            borderBottom: "2px dashed",
            borderColor: disabled ? "neutral.disable" : "purple.default",
          }}
        />
        <SvgBox disabled={disabled ?? false}>
          <DotOutlinedSvg
            style={{
              width: ESpacing._2xs,
              height: ESpacing._2xs,
              flexShrink: 0,
            }}
          />
        </SvgBox>
        <Box
          sx={{
            width: "100%",
            marginInline: ESpacing._3xs,
            marginBottom: "3px",
            borderBottom: "2px dashed",
            borderColor: disabled ? "neutral.disable" : "purple.default",
          }}
        />
      </Box>
    );
  }
};

const FlightIndicator = (props: FlightIndicatorProps) => {
  const isMobile = useMobileQuery();
  const { flightTime, isFerry, variant, disabled } = props;
  if (variant === "segment") {
    const { flight, openDetailPopUp } = props;
    return (
      <Stack
        direction={"column"}
        justifyContent={"space-between"}
        width={"100%"}
        alignItems={"center"}
        overflow={"hidden"}
      >
        <Font variant={isMobile ? EFont.p3 : EFont.p1} color={disabled ? "neutral.disable" : "neutral.black"}>
          {flightTime}
        </Font>
        <Stack
          color={disabled ? "neutral.disable" : "purple.default"}
          flexGrow='1'
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          width={"80%"}
        >
          {isFerry ? (
            <SvgIcon size={isMobile ? SvgIconSize.Mobile : SvgIconSize.Desktop}>
              <FerryIcon />
            </SvgIcon>
          ) : (
            <SvgIcon size={isMobile ? SvgIconSize.Mobile : SvgIconSize.Desktop}>
              <FlightIcon />
            </SvgIcon>
          )}

          <DashedLine disabled={disabled} variant={"single"} />

          <SvgIcon size={isMobile ? SvgIconSize.Mobile : SvgIconSize.Desktop}>
            <PlaceIcon />
          </SvgIcon>
        </Stack>
        <Box
          display={"flex"}
          flexDirection={"row"}
          columnGap={ESpacing._2xs}
          onClick={openDetailPopUp}
          sx={{ cursor: "pointer" }}
        >
          <Font
            variant={isMobile ? EFont.p3 : EFont.p1}
            fontWeight='fontWeightBold'
            key={`${flight.flight_number}-segment`}
            color={disabled ? "neutral.disable" : "neutral.black"}
          >
            {flight.flight_number}
          </Font>
        </Box>
      </Stack>
    );
  } else {
    const { flights, i18nContent, openDetailPopUp } = props;
    return (
      <Stack
        color={disabled ? "neutral.disable" : "purple.default"}
        direction={"column"}
        justifyContent={"space-between"}
        width={"100%"}
        alignItems={"center"}
        overflow={"hidden"}
      >
        {variant !== "small" && flights && flights.length > 1 ? (
          <Box display={"flex"} flexDirection={"row"} columnGap={ESpacing._3xs}>
            <Font variant={isMobile ? EFont.p3 : EFont.p1} color={disabled ? "neutral.disable" : "neutral.black"}>
              {flightTime} |{" "}
            </Font>
            <Font
              onClick={openDetailPopUp}
              variant={isMobile ? EFont.p3 : EFont.p1}
              fontWeight='fontWeightBold'
              color={disabled ? "neutral.disable" : lightTheme.palette.purple.default}
              sx={{ ":hover": { borderBottom: `2px solid ${lightTheme.palette.purple.default}`, cursor: "pointer" } }}
            >
              {i18nContent?.stop}
            </Font>
          </Box>
        ) : variant === "small" && flights && flights.length > 1 ? (
          <Font
            onClick={openDetailPopUp}
            variant={isMobile ? EFont.p3 : EFont.p1}
            fontWeight='fontWeightBold'
            color={disabled ? "neutral.disable" : lightTheme.palette.purple.default}
            sx={{ ":hover": { borderBottom: `2px solid ${lightTheme.palette.purple.default}`, cursor: "pointer" } }}
          >
            {i18nContent?.stop}
          </Font>
        ) : variant === "withDate" || variant === "withLocation" ? (
          <Font variant={isMobile ? EFont.p3 : EFont.p1} color={disabled ? "neutral.disable" : "neutral.black"}>
            {flightTime}
          </Font>
        ) : (
          <Box height={ESpacing._sm} />
        )}
        <Stack
          color={disabled ? "neutral.disable" : "purple.default"}
          flexGrow='1'
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          width={"80%"}
        >
          {isFerry ? (
            <SvgIcon size={isMobile ? SvgIconSize.Mobile : SvgIconSize.Desktop}>
              <FerryIcon />
            </SvgIcon>
          ) : (
            <SvgIcon size={isMobile ? SvgIconSize.Mobile : SvgIconSize.Desktop}>
              <FlightIcon />
            </SvgIcon>
          )}

          <DashedLine disabled={disabled} variant={flights.length > 1 ? "multiple" : "single"} />

          <SvgIcon size={isMobile ? SvgIconSize.Mobile : SvgIconSize.Desktop}>
            <PlaceIcon />
          </SvgIcon>
        </Stack>
        <Box
          display={"flex"}
          flexDirection={"row"}
          columnGap={ESpacing._2xs}
          onClick={openDetailPopUp}
          sx={{ cursor: "pointer" }}
        >
          {flights.map((flight, index) => (
            <Font
              variant={isMobile ? EFont.p3 : EFont.p1}
              fontWeight='fontWeightBold'
              key={`${flight.flight_number}-${index}`}
              color={disabled ? "neutral.disable" : "neutral.black"}
            >
              {flight.flight_number}
            </Font>
          ))}
        </Box>
      </Stack>
    );
  }
};

const FlightStatus = (props: FlightStatusProps) => {
  const isMobile = useMobileQuery();
  const { classType, flightType, variant, disabled, i18nContent, isDisplayCityName } = props;
  if (variant === "segment") {
    return (
      <Box display={"flex"} flexDirection={"column"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"} mb={ESpacing._xs}>
            <Font variant={EFont.p1} color={"black"} fontWeight='fontWeightBold'>
              {flightType}
            </Font>
            {classType && <Tag value={classType} variant='promo_message' sx={{ marginLeft: ESpacing._2xs }} />}
          </Box>
          <Box>
            <Font variant={EFont.p2} color={"black"} mb={ESpacing._xs}>
              {props.flightSegment.departure_date}
            </Font>
          </Box>
        </Box>
        <Font variant={EFont.h3} fontWeight='fontWeightBold' color={"black"}>
          {props.flightSegment.origin} {i18nContent?.to} {props.flightSegment.destination}
        </Font>
        <Box display={"flex"} alignItems={"center"} color={disabled ? "neutral.disable" : "purple.default"}>
          <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
            <Font variant={EFont.h2} fontWeight='500'>
              {props.flightSegment.departure_time}
            </Font>
            <Font variant={EFont.h2} fontWeight='500'>
              {props.flightSegment.origin}
            </Font>
          </Box>
          <FlightIndicator
            disabled={disabled}
            flightTime={`${props.flightSegment.duration.hour}h ${props.flightSegment.duration.minute ? props.flightSegment.duration.minute + " m" : ""}`}
            flight={props.flightSegment}
            isFerry={props.flightSegment.is_ferry}
            variant={variant}
            i18nContent={i18nContent}
            openDetailPopUp={props.openDetailPopUp}
          />
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-end"}
            color={disabled ? "neutral.disable" : "purple.default"}
          >
            <Font variant={EFont.h2} fontWeight='500'>
              {props.flightSegment.arrival_time}
            </Font>
            <Font variant={EFont.h2} fontWeight='500'>
              {props.flightSegment.destination}
            </Font>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Font
            variant={isMobile ? EFont.p3 : EFont.p1}
            color={"black"}
            width={"auto"}
            textAlign={"start"}
            sx={{ overflow: "auto", overflowWrap: "break-word", whiteSpace: "normal" }}
          >
            {i18nContent?.origin_portName}
          </Font>
          <Font
            variant={isMobile ? EFont.p3 : EFont.p1}
            color={"black"}
            width={"auto"}
            textAlign={"end"}
            sx={{ overflow: "auto", overflowWrap: "break-word", whiteSpace: "normal" }}
          >
            {i18nContent?.destination_portName}
          </Font>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          {props.flightSegment.origin_airport_terminal !== null &&
          props.flightSegment.origin_airport_terminal !== undefined ? (
            <Font variant={EFont.p3}>
              {i18nContent?.terminal} {`${props.flightSegment.origin_airport_terminal}`}
            </Font>
          ) : (
            <Font />
          )}

          {props.flightSegment.destination_airport_terminal !== null &&
          props.flightSegment.destination_airport_terminal !== undefined ? (
            <Font variant={EFont.p3}>
              {i18nContent?.terminal} {`${props.flightSegment.destination_airport_terminal}`}
            </Font>
          ) : (
            <Font />
          )}
        </Box>
        <Box>
          <Font variant={EFont.p3} sx={{ display: props.flightSegment.is_ferry ? "block" : "none" }}>
            {i18nContent?.text}
          </Font>
        </Box>
      </Box>
    );
  } else
    return (
      <Box height={"auto"}>
        <Box>
          <Box>
            <Stack>
              {(flightType && variant === "withDate") || variant === "withLocation" ? (
                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} mb={ESpacing._xs}>
                      <Font variant={EFont.p1} color={"black"} fontWeight='fontWeightBold'>
                        {flightType}
                      </Font>
                      {classType && (
                        <Tag value={classType} variant='promo_message' sx={{ marginLeft: ESpacing._2xs }} />
                      )}
                    </Box>
                    <Font variant={isMobile ? EFont.p2 : EFont.p1} color={"black"} mb={ESpacing._xs}>
                      {props.flightJourney.date}
                    </Font>
                  </Box>
                </Box>
              ) : flightType && variant === "small" ? (
                <Box display={"flex"} flexDirection={"column"}>
                  <Box display={"flex"} flexDirection={"row"} alignItems={"center"} mb={ESpacing._xs}>
                    <Font variant={EFont.p1} color={"black"} fontWeight='fontWeightBold'>
                      {flightType}
                    </Font>
                    {classType && <Tag value={classType} variant='promo_message' sx={{ marginLeft: ESpacing._2xs }} />}
                  </Box>
                  <Box
                    display={"flex"}
                    width={"100%"}
                    justifyContent={"space-between"}
                    color={lightTheme.palette.neutral.black}
                  >
                    <Font variant={EFont.p3}>{props.flightJourney.date}</Font>
                    <Font variant={EFont.p3}>{props.flightJourney.flight_time}</Font>
                  </Box>
                </Box>
              ) : null}
              {variant === "withLocation" && (
                <Font variant={EFont.h3} fontWeight='fontWeightBold' color={"black"}>
                  {i18nContent?.origin} {i18nContent?.to} {i18nContent?.destination}
                </Font>
              )}
              <Box display={isDisplayCityName ? "flex" : "none"}>
                <Font variant={EFont.h3} color={"black"}>
                  {i18nContent?.origin_cityName} {i18nContent?.to} {i18nContent?.destination_cityName}
                </Font>
              </Box>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={ESpacing._2xs}
                color={disabled ? "neutral.disable" : "purple.default"}
              >
                <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                  <Font variant={variant === "small" || isMobile ? EFont.p1 : EFont.h2} fontWeight='700'>
                    {props.flightJourney.segments[0].departure_time}
                  </Font>
                  <Font variant={variant === "small" || isMobile ? EFont.p1 : EFont.h2} fontWeight='700'>
                    {props.flightJourney.segments[0].origin
                      ? props.flightJourney.segments[0].origin
                      : props.flightJourney.origin}
                  </Font>
                </Box>
                <FlightIndicator
                  disabled={disabled}
                  flightTime={props.flightJourney.flight_time}
                  flights={props.flightJourney.segments}
                  isFerry={props.flightJourney.segments[0].is_ferry}
                  variant={variant}
                  i18nContent={i18nContent}
                  openDetailPopUp={props.openDetailPopUp}
                />
                <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"} position={"relative"}>
                  <Font variant={variant === "small" || isMobile ? EFont.p1 : EFont.h2} fontWeight='700'>
                    {props.flightJourney.segments[props.flightJourney.segments.length - 1].arrival_time}
                  </Font>
                  <Font variant={variant === "small" || isMobile ? EFont.p1 : EFont.h2} fontWeight='700'>
                    {props.flightJourney.segments[props.flightJourney.segments.length - 1].destination
                      ? props.flightJourney.segments[props.flightJourney.segments.length - 1].destination
                      : props.flightJourney.destination}
                  </Font>
                  {props.flightJourney.day_diff && props.flightJourney.day_diff !== 0 ? (
                    <Font
                      variant={isMobile ? EFont.p3 : EFont.p1}
                      color={disabled ? "neutral.disable" : "purple.default"}
                      position={"absolute"}
                      right={`-${ESpacing._xs}`}
                      top={`-${isMobile ? ESpacing._2xs : ESpacing._s}`}
                    >
                      {props.flightJourney.day_diff > 0
                        ? `+${props.flightJourney.day_diff}`
                        : props.flightJourney.day_diff}
                    </Font>
                  ) : null}
                </Box>
              </Stack>

              <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={ESpacing._s}>
                <Font
                  variant={variant === "small" || isMobile ? EFont.p3 : EFont.p1}
                  width={"auto"}
                  textAlign={"start"}
                  sx={{
                    overflow: "auto",
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                    flex: "initial",
                    width: "49%",
                  }}
                >
                  {i18nContent?.origin_portName}
                </Font>

                <Font
                  variant={variant === "small" || isMobile ? EFont.p3 : EFont.p1}
                  width={"auto"}
                  textAlign={"end"}
                  sx={{
                    overflow: "auto",
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                    flex: "initial",
                    width: "49%",
                  }}
                >
                  {i18nContent?.destination_portName}
                </Font>
              </Stack>
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={0}>
                {props.flightJourney.segments[0].origin_airport_terminal !== null &&
                props.flightJourney.segments[0].origin_airport_terminal !== undefined ? (
                  <Font variant={EFont.p3}>
                    {i18nContent?.terminal} {`${props.flightJourney.segments[0].origin_airport_terminal}`}
                  </Font>
                ) : (
                  <Font />
                )}
                {props.flightJourney.segments[props.flightJourney.segments.length - 1].destination_airport_terminal !==
                  null &&
                props.flightJourney.segments[props.flightJourney.segments.length - 1].destination_airport_terminal !==
                  undefined ? (
                  <Font variant={EFont.p3}>
                    {i18nContent?.terminal}{" "}
                    {`${props.flightJourney.segments[props.flightJourney.segments.length - 1].destination_airport_terminal}`}
                  </Font>
                ) : (
                  <Font />
                )}
              </Stack>
            </Stack>
            <Box>
              <Font
                variant={EFont.p3}
                sx={{
                  display: props.flightJourney.segments.some((segment) => segment.is_ferry) ? "block" : "none",
                }}
              >
                {i18nContent?.text}
              </Font>
            </Box>
          </Box>
        </Box>
      </Box>
    );
};

export { FlightStatus };

import { AppContext, Grid, LoadingBackdrop, Text } from '@hkexpressairwayslimited/ui';
import { Field, Text as TextField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { envConfig } from 'lib/services/env';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo } from 'react';

type RedirectionProps = ComponentProps & {
  fields: {
    languageSpecific: Field<string>;
  };
  params: {
    redirectTo: string;
  };
};

const Redirection = ({ fields, params }: RedirectionProps): JSX.Element => {
  const { isInEditor } = useContext(AppContext);
  const router = useRouter();
  const redirectTo = useMemo(
    () => fields?.languageSpecific?.value || params.redirectTo || '',
    [params.redirectTo]
  );

  useEffect(() => {
    if (!isInEditor && redirectTo) {
      try {
        const nextUrl = new URL(
          redirectTo.replaceAll('{locale}', router.locale || envConfig.defaultLanguage),
          window.location.href
        );

        if (nextUrl.href !== window.location.href) {
          window.location.href = nextUrl.href;
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [redirectTo]);

  if (!isInEditor) {
    return <LoadingBackdrop isOpen={!!redirectTo} hideBackground />;
  }

  return (
    <Grid
      container
      direction="column"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text>Redirection</Text>
      <Text>
        Language Specific: <TextField field={fields?.languageSpecific} />
      </Text>
    </Grid>
  );
};

export default withDatasourceCheck()<RedirectionProps>(Redirection);

import {
  AdditionalBaseFieldInfo,
  Box,
  Button,
  ButtonVariant,
  DateRangePickerField,
  Dropdown,
  EBorders,
  EPaxType,
  EPixel,
  ETripType,
  FieldInputGroup,
  FlightIcon,
  Font,
  Form,
  Grid,
  SearchingDetail,
  SvgIcon,
  TextInput,
  useForm,
} from "@hkexpressairwayslimited/ui/src";
import { Stack, styled } from "@mui/material";
import { format, isAfter, isBefore, isSameDay } from "date-fns";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { SelectPassengers } from "../../../Molecules/SelectPassengers/SelectPassengers";
import { BookingProps } from "../Booking";
import { TripFromTo } from "../TripFromTo/TripFromTo";

const RecentSearchItem = styled(Button)(({ theme }) => ({
  display: "inline-flex",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  alignItems: "center",
  border: `${EBorders.b2} !important`,
  borderRadius: `${EPixel.px12} !important`,
  backgroundColor: "#F6F4F9 !important",
  padding: `${EPixel.px8} !important`,
  marginRight: `${EPixel.px8} !important`,
  marginTop: `${EPixel.px4} !important`,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  minHeight: "56px !important",
  minWidth: "initial !important",
}));

enum Fields {
  Trip = "Trip",
  Passengers = "Passengers",
  PromoCode = "PromoCode",
  CampaignCode = "CampaignCode",
  From = "From",
  To = "To",
  RoundTripDate = "RoundTripDate",
  MultiCityFrom1 = "MultiCityFrom1",
  MultiCityTo1 = "MultiCityTo1",
  MultiCityDepartDate1 = "MultiCityDepartDate1",
  MultiCityFrom2 = "MultiCityFrom2",
  MultiCityTo2 = "MultiCityTo2",
  MultiCityDepartDate2 = "MultiCityDepartDate2",
}

const TripValue = {
  roundTrip: ETripType.roundTrip,
  oneWay: ETripType.oneWay,
  multiCity: ETripType.multiCity,
};

export function BookTrip({
  flightRouteOptions,
  handleBookingTripSubmit,
  i18nContent,
  pageType,
  campaignCode,
  paxMax = 9,
  curTab,
  onDepartureChange,
  lang,
}: BookingProps) {
  const searchingDetail = `${pageType}RecentKey`;
  const defaultValues = {
    [Fields.Trip]: TripValue.roundTrip,
    [Fields.Passengers]: {
      adult: 1,
      child: 0,
      infant: 0,
    },
    [Fields.PromoCode]: "",
    [Fields.CampaignCode]: campaignCode,
    [Fields.From]: "",
    [Fields.To]: "",
    [Fields.RoundTripDate]: undefined,
    [Fields.MultiCityFrom1]: "",
    [Fields.MultiCityTo1]: "",
    [Fields.MultiCityDepartDate1]: undefined,
    [Fields.MultiCityFrom2]: "",
    [Fields.MultiCityTo2]: "",
    [Fields.MultiCityDepartDate2]: undefined,
  };
  type FormValues = Omit<
    typeof defaultValues,
    Fields.RoundTripDate | Fields.MultiCityDepartDate1 | Fields.MultiCityDepartDate2
  > & {
    [Fields.RoundTripDate]: (Date | null)[] | undefined;
    [Fields.MultiCityDepartDate1]: (Date | null)[] | undefined;
    [Fields.MultiCityDepartDate2]: (Date | null)[] | undefined;
  };

  const TripOptions = [
    {
      label: i18nContent.bookTrip.tripOptions.roundTrip,
      value: TripValue.roundTrip,
    },
    {
      label: i18nContent.bookTrip.tripOptions.oneWay,
      value: TripValue.oneWay,
    },
    {
      label: i18nContent.bookTrip.tripOptions.multiCity,
      value: TripValue.multiCity,
    },
  ];
  const FormSchemas = {
    [Fields.Trip]: yup.string().required(),
    [Fields.From]: yup.string().when(Fields.Trip, (value, schema) => {
      return value?.[0] !== TripValue.multiCity
        ? schema.required(i18nContent.bookTrip.fromRequired)
        : schema.notRequired();
    }),
    [Fields.To]: yup.string().when(Fields.Trip, (value, schema) => {
      return value?.[0] !== TripValue.multiCity
        ? schema.required(i18nContent.bookTrip.toRequired)
        : schema.notRequired();
    }),
    [Fields.RoundTripDate]: yup.array().when(Fields.Trip, (value, schema) => {
      return value?.[0] === TripValue.roundTrip
        ? schema.required(i18nContent.bookTrip.dateRequired).compact().min(2, i18nContent.bookTrip.dateRequired)
        : value?.[0] === TripValue.oneWay
          ? schema
              .required(i18nContent.bookTrip.departDateRequired)
              .compact()
              .min(1, i18nContent.bookTrip.departDateRequired)
          : schema.notRequired();
    }),
    [Fields.MultiCityFrom1]: yup.string().when(Fields.Trip, (value, schema) => {
      return value?.[0] === TripValue.multiCity
        ? schema.required(i18nContent.bookTrip.multiCityFrom1Required)
        : schema.notRequired();
    }),
    [Fields.MultiCityTo1]: yup.string().when(Fields.Trip, (value, schema) => {
      return value?.[0] === TripValue.multiCity
        ? schema.required(i18nContent.bookTrip.multiCityTo1Required)
        : schema.notRequired();
    }),
    [Fields.MultiCityDepartDate1]: yup.array().when(Fields.Trip, (value, schema) => {
      return value?.[0] === TripValue.multiCity
        ? schema
            .required(i18nContent.bookTrip.multiCityDepartDateRequired)
            .compact()
            .min(1, i18nContent.bookTrip.multiCityDepartDateRequired)
            .test("checkDate1", i18nContent.bookTrip.dateErrorMessage, function (this: yup.TestContext, value) {
              const date2: string[] = this.resolve(yup.ref(Fields.MultiCityDepartDate2));
              const isMultiCity = this.resolve(yup.ref(Fields.Trip)) === TripValue.multiCity;
              if (isMultiCity && date2 && date2[0] && value) {
                return (
                  isAfter(new Date(date2[0] as string), new Date(value[0])) ||
                  isSameDay(new Date(date2[0] as string), new Date(value[0]))
                );
              }
              return true;
            })
        : schema.notRequired();
    }),
    [Fields.MultiCityFrom2]: yup.string().when(Fields.Trip, (value, schema) => {
      return value?.[0] === TripValue.multiCity
        ? schema.required(i18nContent.bookTrip.multiCityFrom2Required)
        : schema.notRequired();
    }),
    [Fields.MultiCityTo2]: yup.string().when(Fields.Trip, (value, schema) => {
      return value?.[0] === TripValue.multiCity
        ? schema.required(i18nContent.bookTrip.multiCityTo2Required)
        : schema.notRequired();
    }),
    [Fields.MultiCityDepartDate2]: yup.array().when(Fields.Trip, (value, schema) => {
      return value?.[0] === TripValue.multiCity
        ? schema
            .required(i18nContent.bookTrip.multiCityReturnDateRequired)
            .compact()
            .min(1, i18nContent.bookTrip.multiCityReturnDateRequired)
            .test("checkDate2", i18nContent.bookTrip.dateErrorMessage, function (this: yup.TestContext, value) {
              const date1: string[] = this.resolve(yup.ref(Fields.MultiCityDepartDate1));
              const isMultiCity = this.resolve(yup.ref(Fields.Trip)) === TripValue.multiCity;
              if (isMultiCity && date1 && date1[0] && value) {
                return (
                  isBefore(new Date(date1[0] as string), new Date(value[0])) ||
                  isSameDay(new Date(date1[0] as string), new Date(value[0]))
                );
              }

              return true;
            })
        : schema.notRequired();
    }),
  };
  const [trip, setTrip] = useState(TripValue.roundTrip);
  const [roundTripDate, setRoundTripDate] = useState([null, null]);
  const [win, setWin] = useState<Window | null>(null);
  const [localSearchDetails, setLocalSearchDetails] = useState([]);
  const formHook = useForm<FormValues>({
    defaultValues,
    schema: yup.object().shape(FormSchemas),
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWin(window);
      setLocalSearchDetails(JSON.parse(window.localStorage.getItem(searchingDetail) || "[]"));
    }
  }, []);

  useEffect(() => {
    if (curTab !== 0) {
      formHook.clearErrors();
    }
  }, [curTab]);

  useEffect(() => {
    const subscription = formHook.watch((value, { name, type }) => {
      if (name === Fields.From || name === Fields.MultiCityFrom1) {
        if (onDepartureChange) {
          const origin = value[Fields.From] || value[Fields.MultiCityFrom1];
          if (origin) {
            const market =
              flightRouteOptions.sales_port_grouping.find((e: any) =>
                e.ports.find((n: any) => n.airport_code === origin)
              )?.default_currency ?? "";
            onDepartureChange({
              code: origin,
              market,
            });
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [formHook, formHook.watch, flightRouteOptions]);

  const saveLocalStorage = (data: any) => {
    const details = JSON.parse((win as Window)?.localStorage.getItem(searchingDetail) || "[]");
    const newData = { ...data, campaignCode: data.campaignCode || "" };
    for (let i = 0; i < details.length; i++) {
      if (isEqual(newData, details[i])) {
        return details;
      }
    }
    if (details.length >= 3) {
      details.pop();
    }
    details.unshift(newData);
    (win as Window)?.localStorage.setItem(searchingDetail, JSON.stringify(details));
    return details;
  };

  const getMarket = (code: string) => {
    const salesPortGrouping = flightRouteOptions?.sales_port_grouping;
    const curItem = salesPortGrouping.find((salesPort: any) => {
      return salesPort?.ports.find((port: any) => {
        return port?.airport_code === code;
      });
    });
    return curItem?.market || "";
  };
  const handSubmit = (formValues: any) => {
    const data = formValues?.data;
    const trips =
      data[Fields.Trip] === TripValue.roundTrip
        ? [
            {
              fromMarket: getMarket(data[Fields.From]),
              toMarket: getMarket(data[Fields.To]),
              from: data[Fields.From],
              to: data[Fields.To],
              date: data[Fields.RoundTripDate]?.[0]?.toString(),
            },
            {
              fromMarket: getMarket(data[Fields.To]),
              toMarket: getMarket(data[Fields.From]),
              from: data[Fields.To],
              to: data[Fields.From],
              date: data[Fields.RoundTripDate]?.[1]?.toString(),
            },
          ]
        : data[Fields.Trip] === TripValue.oneWay
          ? [
              {
                fromMarket: getMarket(data[Fields.From]),
                toMarket: getMarket(data[Fields.To]),
                from: data[Fields.From],
                to: data[Fields.To],
                date: data[Fields.RoundTripDate]?.[0]?.toString(),
              },
            ]
          : [
              {
                fromMarket: getMarket(data[Fields.MultiCityFrom1]),
                toMarket: getMarket(data[Fields.MultiCityTo1]),
                from: data[Fields.MultiCityFrom1],
                to: data[Fields.MultiCityTo1],
                date: data[Fields.MultiCityDepartDate1]?.[0]?.toString(),
              },
              {
                fromMarket: getMarket(data[Fields.MultiCityFrom2]),
                toMarket: getMarket(data[Fields.MultiCityTo2]),
                from: data[Fields.MultiCityFrom2],
                to: data[Fields.MultiCityTo2],
                date: data[Fields.MultiCityDepartDate2]?.[0]?.toString(),
              },
            ];
    const saveData: SearchingDetail = {
      tripType: data[Fields.Trip],
      trips,
      passenger_count: {
        [EPaxType.Adult]: data[Fields.Passengers]?.adult,
        [EPaxType.Child]: data[Fields.Passengers]?.child,
        [EPaxType.Infant]: data[Fields.Passengers]?.infant,
      },
      promoCode: data[Fields.PromoCode],
      campaignCode: data[Fields.CampaignCode],
      homePageUrl: window.location.href,
    };
    console.log(formValues, "formValues");
    const details = pageType !== "B2B" && saveLocalStorage(saveData);
    setLocalSearchDetails(details);
    handleBookingTripSubmit && handleBookingTripSubmit(saveData);
    // dispatch(showLoadingBackdrop());
    // dispatch(storeFlightSearching(saveData));
    // RouterInstance.push("/:lang/flight-booking/select");
  };
  const handleTripValueChange = (info: AdditionalBaseFieldInfo, e: ETripType) => {
    setTrip(e);
    if (e === TripValue.oneWay) {
      formHook.setValue(Fields.RoundTripDate, roundTripDate?.[0] ? [roundTripDate?.[0], null] : undefined);
    }
    setTimeout(() => {
      formHook.clearErrors();
    });
  };
  const handleRoundTripDateChange = (info: AdditionalBaseFieldInfo, e: any) => {
    formHook.setValue(Fields.RoundTripDate, e, { shouldValidate: true });
    setRoundTripDate(e);
  };
  const handleRecentSearch = (index: number) => {
    const details = JSON.parse((win as Window)?.localStorage.getItem(searchingDetail) || "[]");
    const curSearch = details[index];
    if (curSearch) {
      formHook.clearErrors();
      setTrip(curSearch.tripType);
      const { passenger_count, trips = [] } = curSearch;
      formHook.setValue(Fields.Trip, curSearch.tripType);
      formHook.setValue(Fields.PromoCode, curSearch.promoCode);
      formHook.setValue(Fields.Passengers, {
        adult: passenger_count?.Adult,
        child: passenger_count?.Child,
        infant: passenger_count?.Infant,
      });
      if (curSearch.tripType === TripValue.roundTrip) {
        formHook.setValue(Fields.From, trips[0].from);
        formHook.setValue(Fields.To, trips[0].to);
        formHook.setValue(Fields.RoundTripDate, [trips[0]?.date, trips[1]?.date]);
        setRoundTripDate([trips[0]?.date, trips[1]?.date]);
      }
      if (curSearch.tripType === TripValue.oneWay) {
        formHook.setValue(Fields.From, trips[0].from);
        formHook.setValue(Fields.To, trips[0].to);
        formHook.setValue(Fields.RoundTripDate, [trips[0]?.date, null]);
        setRoundTripDate([trips[0]?.date, null]);
      }
      if (curSearch.tripType === TripValue.multiCity) {
        formHook.setValue(Fields.MultiCityFrom1, trips[0].from);
        formHook.setValue(Fields.MultiCityTo1, trips[0].to);
        formHook.setValue(Fields.MultiCityFrom2, trips[1].from);
        formHook.setValue(Fields.MultiCityTo2, trips[1].to);
        formHook.setValue(Fields.MultiCityDepartDate1, [trips[0]?.date, null]);
        formHook.setValue(Fields.MultiCityDepartDate2, [trips[1]?.date, null]);
      }
    }
  };
  const tripFromToContent = {
    from: i18nContent.bookTrip.from,
    to: i18nContent.bookTrip.to,
    airportCode: i18nContent.bookTrip.airportCode,
  };
  const today = new Date();
  const minDate = today;
  return (
    <Form formHook={formHook} onSubmit={handSubmit}>
      <Grid container spacing={2}>
        <Grid item lg={10} md={12} xs={12}>
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item lg={3} md={6} xs={12} order={1}>
              <FieldInputGroup
                onValueChange={handleTripValueChange}
                fullWidth
                names={[Fields.Trip]}
                helperText={i18nContent.bookTrip.trip}
              >
                <Dropdown fullWidth placeholder={i18nContent.bookTrip.trip} options={TripOptions} />
              </FieldInputGroup>
            </Grid>
            <Grid item lg={3} md={6} xs={12} order={2}>
              <FieldInputGroup names={[Fields.Passengers]} fullWidth helperText={i18nContent.bookTrip.passengers}>
                <SelectPassengers
                  adultLabel={i18nContent.bookTrip.passengersAdult}
                  childLabel={i18nContent.bookTrip.passengersChild}
                  childToolTipLabel={i18nContent.bookTrip.passengersChildToolTip}
                  childLabelTip={i18nContent.bookTrip.passengersChildTip}
                  infantLabel={i18nContent.bookTrip.passengersInfant}
                  infantToolTipLabel={i18nContent.bookTrip.passengersInfantToolTip}
                  infantLabelTip={i18nContent.bookTrip.passengersInfantTip}
                  popUpTitle={i18nContent.bookTrip.passengersPopUpTitle}
                  max={paxMax}
                />
              </FieldInputGroup>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sx={{ order: { lg: 3, md: 8, xs: 8 }, flexDirection: { xs: "row" } }}>
              <Grid item lg={6} xs={12}>
                {!campaignCode && (
                  <FieldInputGroup
                    fullWidth
                    names={[Fields.PromoCode]}
                    helperText={i18nContent.bookTrip.promoCodeOptional}
                  >
                    <TextInput placeholder={i18nContent.bookTrip.promoCodeOptional} />
                  </FieldInputGroup>
                )}
              </Grid>
            </Grid>
            <Grid item md={6} order={9} sx={{ display: { xs: "none", md: "flex", lg: "none" } }}>
              <StyledButton borderRadius={EPixel.px28} submit fullWidth variant={ButtonVariant.Primary}>
                {i18nContent.bookTrip.search}
              </StyledButton>
            </Grid>
            {trip !== TripValue.multiCity && (
              <>
                <Grid item md={6} xs={12} order={4}>
                  <TripFromTo
                    formHook={formHook}
                    isChangeLeftRight
                    flightRouteOptions={flightRouteOptions}
                    names={[Fields.From, Fields.To]}
                    i18nContent={tripFromToContent}
                  />
                </Grid>
                <Grid item md={6} xs={12} order={5}>
                  <FieldInputGroup
                    onValueChange={handleRoundTripDateChange}
                    fullWidth
                    names={[Fields.RoundTripDate]}
                    helperText={[i18nContent.bookTrip.date]}
                  >
                    <DateRangePickerField
                      lang={lang}
                      popUpTitle={i18nContent.bookTrip.chooseTravelDates}
                      disabledEndDate={trip === TripValue.oneWay}
                      placeholder={[i18nContent.bookTrip.departDate, i18nContent.bookTrip.returnDate]}
                      minDate={minDate}
                      maxDate={undefined}
                    ></DateRangePickerField>
                  </FieldInputGroup>
                </Grid>
              </>
            )}
            {/* multi city */}
            {trip === TripValue.multiCity && (
              <>
                <Grid item md={6} xs={12} order={4}>
                  <TripFromTo
                    formHook={formHook}
                    flightRouteOptions={flightRouteOptions}
                    names={[Fields.MultiCityFrom1, Fields.MultiCityTo1]}
                    i18nContent={tripFromToContent}
                  />
                </Grid>
                <Grid item md={6} xs={12} order={5}>
                  <FieldInputGroup
                    fullWidth
                    names={[Fields.MultiCityDepartDate1]}
                    helperText={i18nContent.bookTrip.departDateTrip1}
                    onValueChange={(info, e) => {
                      const data2 = formHook.getValues(Fields.MultiCityDepartDate1);
                      formHook.setValue(Fields.MultiCityDepartDate1, e, { shouldValidate: true });
                      data2?.[0] && formHook.trigger(Fields.MultiCityDepartDate2);
                    }}
                  >
                    <DateRangePickerField
                      popUpTitle={i18nContent.bookTrip.chooseTravelDates}
                      isSingleDate
                      placeholder={[i18nContent.bookTrip.departDateTrip1]}
                      minDate={minDate}
                      maxDate={undefined}
                    ></DateRangePickerField>
                  </FieldInputGroup>
                </Grid>
                <Grid item md={6} xs={12} order={6}>
                  <TripFromTo
                    formHook={formHook}
                    flightRouteOptions={flightRouteOptions}
                    names={[Fields.MultiCityFrom2, Fields.MultiCityTo2]}
                    i18nContent={tripFromToContent}
                  />
                </Grid>
                <Grid item md={6} xs={12} order={7}>
                  <FieldInputGroup
                    fullWidth
                    names={[Fields.MultiCityDepartDate2]}
                    helperText={i18nContent.bookTrip.departDateTrip2}
                    onValueChange={(info, e) => {
                      const data1 = formHook.getValues(Fields.MultiCityDepartDate1);
                      formHook.setValue(Fields.MultiCityDepartDate2, e, { shouldValidate: true });
                      data1?.[0] && formHook.trigger(Fields.MultiCityDepartDate1);
                    }}
                  >
                    <DateRangePickerField
                      lang={lang}
                      popUpTitle={i18nContent.bookTrip.chooseTravelDates}
                      isSingleDate
                      placeholder={[i18nContent.bookTrip.departDateTrip2]}
                      minDate={minDate}
                      maxDate={undefined}
                    ></DateRangePickerField>
                  </FieldInputGroup>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          md={2}
          xs={12}
          display='flex'
          alignItems='flex-end'
          sx={{ display: { xs: "flex", md: "none", lg: "flex" } }}
        >
          <StyledButton borderRadius={EPixel.px28} submit fullWidth variant={ButtonVariant.Primary}>
            {i18nContent.bookTrip.search}
          </StyledButton>
        </Grid>
      </Grid>
      {localSearchDetails?.length > 0 && flightRouteOptions && pageType !== "B2B" && (
        <Box>
          <Box sx={{ mt: EPixel.px12 }}>{i18nContent.bookTrip.recentSearches}</Box>
          <Box>
            {localSearchDetails.map((detail: any, index: number) => {
              const { trips } = detail;
              return (
                <RecentSearchItem custom onClick={() => handleRecentSearch(index)} key={index}>
                  <SvgIcon sx={{ width: EPixel.px24, height: EPixel.px24, mr: EPixel.px8 }}>
                    <FlightIcon />
                  </SvgIcon>
                  <Stack
                    display={"inline-flex"}
                    sx={(theme) => ({
                      flexDirection: "row",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "14px",
                      },
                    })}
                  >
                    <Font sx={{ mr: EPixel.px8 }} fontWeight='fontWeightBold'>
                      {detail.tripType === TripValue.roundTrip || detail.tripType === TripValue.oneWay
                        ? `${trips[0].from} - ${trips[0].to}`
                        : i18nContent.bookTrip.multiCity}
                    </Font>
                    <Box>
                      {(detail.tripType === TripValue.roundTrip || detail.tripType === TripValue.multiCity) &&
                        `${format(trips[0].date, "dd MMM")} - ${format(trips[1].date, "dd MMM")}`}
                      {detail.tripType === TripValue.oneWay && `${format(trips[0].date, "dd MMM")}`}
                    </Box>
                  </Stack>
                </RecentSearchItem>
              );
            })}
          </Box>
        </Box>
      )}
    </Form>
  );
}

import { Dropdown, FormFieldProps, SelectInputProps, TextInput, TextInputProps } from "@hkexpressairwayslimited/ui/src";
import { Stack } from "@mui/material";
import { ChangeEvent, ForwardedRef, forwardRef } from "react";

import classes from "./CompositeMobileField.module.scss";

type CompositeMobileFieldValue = {
  countryCode: string;
  mobileNumber: string;
  country?: string;
};

export type CompositeMobileFieldProps<OptionValue extends string, Multiple extends boolean> = {
  value?: CompositeMobileFieldValue;
  onChange?: (value: CompositeMobileFieldValue) => void;
  countryCodeProps?: SelectInputProps<OptionValue, Multiple>;
  mobileProps?: TextInputProps;
  defaultValue?: string;
} & Omit<Partial<FormFieldProps>, "onChange">;

export const CompositeMobileField = forwardRef(
  <OptionValue extends string, Multiple extends boolean = false>(
    {
      value,
      defaultValue,
      onChange,
      onBlur,
      error,
      isValidating,
      isDirty,
      invalid,
      isTouched,
      disabled,
      groupSubFieldHandle,
      countryCodeProps = {},
      mobileProps = {},
      ...other
    }: CompositeMobileFieldProps<OptionValue, Multiple>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { areOptionsEqual, ...restCountryCodeProps }: SelectInputProps<string, boolean> =
      countryCodeProps as SelectInputProps<string, boolean>;

    const handleChangeCountryCode = (countryCode: string) => {
      let country = "";
      (restCountryCodeProps.options || []).find((option) => {
        if ("value" in option && option.value === countryCode) {
          country = option.country ?? "";
          return true;
        } else if ("options" in option) {
          return option.options.find((subOption) => {
            if (subOption.value === countryCode) {
              country = subOption.country ?? "";
              return true;
            }
          })?.label;
        }
      });
      onChange?.({
        ...value,
        countryCode,
        country: country,
      });
    };

    const handleChangeMobileNumber = (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.({ ...value, mobileNumber: event.target.value });
    };

    const handleInputMobileNumber = (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (typeof newValue === "string") {
        event.target.value = newValue.replace(/\D/g, "");
      }
    };

    return (
      <Stack direction='row' spacing={2} className={classes.compositeMobileField} onBlur={onBlur} ref={ref}>
        <Dropdown
          className={classes.compositeMobileField_countryCode}
          groupSubFieldHandle={groupSubFieldHandle}
          value={value?.countryCode}
          onChange={handleChangeCountryCode}
          defaultValue={defaultValue}
          {...restCountryCodeProps}
          disabled={disabled}
          canSearch
        />
        <TextInput
          fullWidth
          className={classes.compositeMobileField_mobileNumber}
          value={value?.mobileNumber}
          onChange={handleChangeMobileNumber}
          {...mobileProps}
          type='tel'
          onInput={handleInputMobileNumber}
          disabled={disabled}
        />
      </Stack>
    );
  }
);
CompositeMobileField.displayName = "CompositeMobileField";

import { Footer as FooterUi } from '@hkexpressairwayslimited/ui';
import {
  Field,
  ImageField,
  Placeholder,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssImage } from 'lib/components';
import { JssRichText } from 'lib/components/JssRichText';
import { endpoints, fetchAPI } from 'lib/services/api';
import { useTransContent } from 'lib/transContent';
import { useRouter } from 'next/router';
import { useState } from 'react';

type ContentBlockProps = ComponentProps & {
  fields: {
    subscriptionFieldLabel: Field<string>;
    subscribeBtnLabel: Field<string>;
    subscribeBtnHandle: (email: string) => void;
    logo: ImageField;
    copyrightLabel: Field<string>;
    socialShareTitle: Field<string>;
    subscribeFieldHelper: Field<string>;
    emailFieldRequiredMsg: Field<string>;
  };
};

const subscriptionByEmail = async (
  email: string,
  contactListType: string,
  languageLocale: string = 'en-hk'
) => {
  // const languageList = envConfig.languageList;
  // const path = window?.location.pathname.slice(1);
  // const pathArr = path.split('/') || [];
  // const languageLocale = pathArr[0] || '';
  // const isInclude = languageList.includes(languageLocale);
  // const language = isInclude ? languageLocale : 'en-hk';

  const body = {
    recipient_list: [
      {
        email: email,
        language: languageLocale,
      },
    ],
    contact_list_type: contactListType,
  };
  const result = await fetchAPI(endpoints.subscriptionByEmail, {
    method: 'POST',
    cache: 'no-store',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
  return result;
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const Footer = ({ fields, rendering }: ContentBlockProps): JSX.Element => {
  const footerProps = {
    subscriptionField: {
      label: <Text field={fields?.subscriptionFieldLabel} />,
      helperText: <Text field={fields?.subscribeFieldHelper} />,
    },
    subscribeBtnLabel: <Text field={fields?.subscribeBtnLabel} />,
    logo: (
      <JssImage
        field={fields?.logo}
        width={0}
        height={0}
        style={{ width: 'auto', height: '100%' }}
      />
    ),
    emailFieldRequiredMsg: fields?.emailFieldRequiredMsg?.value,
    editor: {
      emailFieldRequiredMsg: <Text field={fields?.emailFieldRequiredMsg} />,
    },
    copyrightLabel: <JssRichText field={fields?.copyrightLabel} />,
    navGroups: <Placeholder name="nav-groups" rendering={rendering} />,
    socialShareTitle: <Text field={fields?.socialShareTitle} />,
    socialShareChannels: <Placeholder name="social-share-channels" rendering={rendering} />,
    // subscribeBtnHandle: (email: string) => {
    //   subscriptionByEmail(email, 'MARKETING');
    //   subscriptionByEmail(email, 'PROMOTION');
    // },
  };
  const { t } = useTransContent();
  const [subscribeStatus, setSubscribeStatus] = useState<string>('');
  const router = useRouter();
  const subscribeBtnHandle = async (email: string) => {
    const marketRes = await subscriptionByEmail(email, 'MARKETING', router.locale);
    const promoRes = await subscriptionByEmail(email, 'PROMOTION', router.locale);

    if (marketRes.metadata.status_code === 201 && promoRes.metadata.status_code === 201) {
      setSubscribeStatus(t('web.footer.subscribe.success') as string);
    } else {
      setSubscribeStatus(t('web.footer.subscribe.fail') as string);
    }
  };
  return (
    <>
      <FooterUi
        {...footerProps}
        subscribeBtnHandle={subscribeBtnHandle}
        subscribeStatus={subscribeStatus}
      />
    </>
  );
};

export default withDatasourceCheck()<ContentBlockProps>(Footer);

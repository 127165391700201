import { useEffect } from "react";

const addHtmlClass = (className: string) => document.documentElement.classList.add(className);
const removeHtmlClass = (className: string) => document.documentElement.classList.remove(className);

export const useHtmlClass = (className: string[] | string, enabled = true) => {
  useEffect(() => {
    // Set up
    if (enabled) {
      className instanceof Array ? className.map(addHtmlClass) : addHtmlClass(className);
    } else {
      className instanceof Array ? className.map(removeHtmlClass) : removeHtmlClass(className);
    }

    // Clean up
    return () => {
      className instanceof Array ? className.map(removeHtmlClass) : removeHtmlClass(className);
    };
  }, [className, enabled]);
};
